import React, { useEffect, useState } from 'react';
import { Col, Menu, Row, Tooltip } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import {
  UserOutlined,
  LogoutOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
  FileSyncOutlined,
  BranchesOutlined,
  LayoutOutlined,
} from '@ant-design/icons';

import './SideMenuView.less';
import logo from '../../../../src/assets/img/logo.png';
import { ReactComponent as IconPeso } from '../../../../src/assets/img/svg/IconPeso.svg';
import { ReactComponent as IconTruck } from '../../../../src/assets/img/svg/IconTruck.svg';
import { ReactComponent as IconGas } from '../../../../src/assets/img/svg/IconGas.svg';

const getUser = () => {
  const data: any = localStorage.getItem('user');
  return data;
};

const SideMenuView = ({ siderCollapsed, collapseToggle }: any) => {
  const location = useLocation();
  const history = useHistory();
  const [user, setUser]: any = useState();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  const data = JSON.parse(getUser());
  useEffect(() => {
    const keyPath = keyPathTransformer(`/${location.pathname.split('/')[1]}`);
    setCurrentPath(keyPath);
    verifyUser();
  }, [location.pathname]);

  const verifyUser = async () => {
    // if(data){
    //   const isDeleted = await auth.verifyUser(data._id)
    //   if(isDeleted) onLogout();
    // }
  };

  useEffect(() => {
    if (!data) {
      onLogout();
    }
  }, []);
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  const keyPathTransformer = (value: string) => {
    switch (value) {
      case '/marketplace': {
        return '/marketplace';
      }
      default: {
        return '/dashboard';
      }
    }
  };

  const onLogout = () => {
    localStorage.clear();
    history.push('/login');
  };

  const onToggleMenus = async (action: string) => {
    setCurrentPath(action);

    // Refresh the page or route again
    await history.push('/');
    history.push(action);
  };

  const menu = (
    <Menu>
      <Menu.Item key='2' className='link-secondary'>
        <span onClick={() => onLogout()} className='c-pointer fw-600'>
          <LogoutOutlined /> Log Out
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Row justify='center'>
        <Col>
          <img className={!siderCollapsed ? 'img-logo' : 'img-logo-collapsed'} src={logo} alt='' />
        </Col>
      </Row>
      <Menu
        mode='inline'
        selectedKeys={[currentPath]}
        defaultSelectedKeys={[currentPath === '/' ? '/dashboard' : currentPath]}
        className='text-gray'
      >
        <Menu.Item
          key={currentPath === '/' ? '/' : '/dashboard'}
          onClick={() => onToggleMenus('/dashboard')}
        >
          {siderCollapsed ? <Tooltip placement="right" title={
            <>
              <LayoutOutlined className='margin-right-s' />
              <span>Dashboard</span>
            </>
          }>
            <LayoutOutlined />
          </Tooltip> : <></>}
          <LayoutOutlined />
          <span>Dashboard</span>
        </Menu.Item>
        <Menu.Item key='/customers' onClick={() => onToggleMenus('/customers')}>
          {siderCollapsed ? <Tooltip placement="right" title={
            <>
              <TeamOutlined className='margin-right-s' />
              <span>Customers</span>
            </>
          }>
            <TeamOutlined />
          </Tooltip> : <></>}
          <TeamOutlined />
          <span>Customers</span>
        </Menu.Item>
        <Menu.Item key='/drivers' onClick={() => onToggleMenus('/drivers')}>
          {siderCollapsed ? <Tooltip placement="right" title={
            <>
              <UserOutlined className='margin-right-s' />
              <span>Drivers</span>
            </>
          }>
            <UserOutlined />
          </Tooltip> : <></>}
          <UserOutlined />
          <span>Drivers</span>
        </Menu.Item>
        <Menu.Item key='/products' onClick={() => onToggleMenus('/products')}>
          {siderCollapsed ? <Tooltip placement="right" title={
            <div className='tooltip-container'>
              <IconGas className='margin-right-s tooltip-icon' />
              <span>Products</span>
            </div>
          }>
            <IconGas className='img-icon margin-right-s' />
          </Tooltip> : <>
            <IconGas className='img-icon margin-right-s' />
            {!siderCollapsed ? <span>Products</span> : <></>}
          </>}
        </Menu.Item>

        <Menu.Item
          key='/trucks'
          onClick={() => onToggleMenus('/trucks')}
          className='displayFlex'
        >
          {siderCollapsed ? <Tooltip placement="right" title={
            <div className='tooltip-container'>
              <IconTruck className='margin-right-s tooltip-icon' />
              <span>Trucks</span>
            </div>
          }>
            <IconTruck className='img-icon margin-right-s' />
          </Tooltip> : <>
            <IconTruck className='img-icon margin-right-s' />
            {!siderCollapsed ? <span>Trucks</span> : <></>}
          </>}
        </Menu.Item>
        <Menu.Item key='/orders' onClick={() => onToggleMenus('/orders')}>
          {siderCollapsed ? <Tooltip placement="right" title={
            <>
              <ShoppingCartOutlined className='margin-right-s' />
              <span>Orders</span>
            </>
          }>
            <ShoppingCartOutlined />
          </Tooltip> : <></>}
          <ShoppingCartOutlined />
          <span>Orders</span>
        </Menu.Item>
        <Menu.Item
          key='/daily-route-dispatch'
          onClick={() => onToggleMenus('/daily-route-dispatch')}
        >
          {siderCollapsed ? <Tooltip placement="right" title={
            <>
              <BranchesOutlined className='margin-right-s' />
              <span>Daily Route Dispatch</span>
            </>
          }>
            <BranchesOutlined />
          </Tooltip> : <></>}
          <BranchesOutlined />
          <span>Daily Route Dispatch</span>
        </Menu.Item>
        <Menu.Item key='/sales' onClick={() => onToggleMenus('/sales')}>
          {siderCollapsed ? <Tooltip placement="right" title={
            <div className='tooltip-container'>
              <IconPeso className='margin-right-s tooltip-icon' />
              <span>Sales</span>
            </div>
          }>
            <IconPeso className='img-icon margin-right-s' />
          </Tooltip> : <>
            <IconPeso className='img-icon margin-right-s' />
            {!siderCollapsed ? <span>Sales</span> : <></>}
          </>}
        </Menu.Item>
        <Menu.Item
          key='/driving-boarding-history'
          onClick={() => onToggleMenus('/driving-boarding-history')}
        >
          {siderCollapsed ? <Tooltip placement="right" title={
            <>
              <FileSyncOutlined className='margin-right-s' />
              <span>Driving Boarding History</span>
            </>
          }>
            <FileSyncOutlined />
          </Tooltip> : <></>}
          <FileSyncOutlined />
          <span>Driving Boarding History</span>
        </Menu.Item>
        <Menu.Item
          key='/begin-end-inventory'
          onClick={() => onToggleMenus('/begin-end-inventory')}
          className='margin-bottom-s'
        >
          {siderCollapsed ? <Tooltip placement="right" title={
            <div className='tooltip-container'>
              <IconGas className='margin-right-s tooltip-icon' />
              <span>Beginning & Ending Inventory</span>
            </div>
          }>
            <IconGas className='img-icon margin-right-s' />
          </Tooltip> : <>
            <Row className='align-items-center'>
              <Col>
                <IconGas className='img-icon margin-right-s' />
              </Col>
              {!siderCollapsed ? <Col>
                <Row className='height-18'>Beginning &</Row>
                <Row>Ending Inventory</Row>
              </Col> : <></>}
            </Row>
          </>}
        </Menu.Item>
      </Menu>
      {!siderCollapsed ? (
        <div
          className='sidebar--overlay'
          onClick={() => collapseToggle(!siderCollapsed)}
        ></div>
      ) : null}
    </>
  );
};

export default SideMenuView;
